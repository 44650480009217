export const setReloadApp = value => ({
  type: "reloadApp",
  value
});

export const onEmojiPicker = value => ({
  type: "showEmojiPicker",
  value
});

export const onUserProfileTab = value => ({
  type: "setUserProfileTab",
  value
});

export const onSetTheme = value => ({
  type: "setTheme",
  value
});
