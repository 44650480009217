import React from "react";
import styles from "./styles.module.css";
import PropTypes from "prop-types";
import { Avatar, Spin } from "antd";
import get from "lodash/get";

const LayoutHeader = ({ nextUser, onlineMembers, messages }) => {
  return nextUser ? (
    <div className={styles.headerStyle}>
      <Avatar
        src={nextUser.avatar}
        size="default"
        className={styles.selectedUserAvatar}
      />

      <div style={{ textTransform: "capitalize" }}>
        <div className={styles.names}>
          {nextUser.firstname} {nextUser.lastname}
        </div>

        <div className={styles.status}>
          {get(onlineMembers, `${nextUser.username}.status`) === "online" ? (
            <div className={styles.statusWrapper}>
              online
              <div className={styles.online} />
            </div>
          ) : (
            <div className={styles.statusWrapper}>
              offline
              <div className={styles.offline} />
            </div>
          )}
        </div>
      </div>
      <span className={styles.conversationLoader}>{!messages && <Spin />}</span>
    </div>
  ) : (
    <Spin />
  );
};

LayoutHeader.propTypes = {
  messages: PropTypes.array,
  nextUser: PropTypes.object,
  onlineMembers: PropTypes.object
};

export default LayoutHeader;
