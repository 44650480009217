const initialState = {
  user: {
    id: null,
    firstname: null,
    lastname: null,
    type: null
  },
  isLoggedIn: false,
  token: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "onLoginUser":
      return {
        ...state,
        user: action.user,
        token: action.token,
        isLoggedIn: true
      };
    case "resetInitialState":
      return (state = initialState);
    default:
      return state;
  }
}
