import React from "react";
import styles from "./styles.module.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { submitLogin } from "../../redux/auth/thunks";
import {
  Form,
  Icon,
  Input,
  Button,
  Avatar,
  Typography,
  Row,
  Col,
  Alert
} from "antd";

const { Title } = Typography;

class NormalLoginForm extends React.Component {
  state = {
    loading: false,
    showResponse: false,
    result: {}
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        let result = await this.props.submit({
          username: values.username,
          password: values.password
        });

        if (!result.success) {
          this.setState({ result: result, loading: false, showResponse: true });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { result, showResponse } = this.state;

    return (
      <div className={styles.loginWrapper}>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={8} xs={18} xl={6} sm={14} md={8} lg={6}>
            <div className={styles.chatIconWrapper}>
              <Avatar src="/chat-icon.png" size={80}></Avatar>
              <Title level={4}>My Portal</Title>
            </div>
            <Form onSubmit={this.handleSubmit}>
              <Form.Item hasFeedback>
                {getFieldDecorator("username", {
                  rules: [
                    { required: true, message: "Please input your username!" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Username"
                  />
                )}
              </Form.Item>
              <Form.Item hasFeedback>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input your Password!" }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Password"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {/* TODO <a className={styles.loginFormForgot} href="/forgot-password">
                  Forgot password
                </a> */}
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.loginFormButton}
                  loading={this.state.loading}
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
            {showResponse && (
              <Alert
                style={{ transition: "visibility 0s, opacity 0.5s linear" }}
                message={result.message}
                type={result.type}
                showIcon
                closable
                onClose={() => this.setState({ showResponse: false })}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

NormalLoginForm.propTypes = {
  form: PropTypes.object,
  submit: PropTypes.func
};

const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(
  NormalLoginForm
);

const mapDispatchToProps = {
  submit: submitLogin
};

export default connect(null, mapDispatchToProps)(WrappedNormalLoginForm);
