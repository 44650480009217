import React from "react";
import styles from "./styles.module.css";
import PropTypes from "prop-types";
import { List, Badge, Avatar, Input } from "antd";
import { NavLink } from "react-router-dom";
import get from "lodash/get";
import { searchUser } from "../../commons";
import clsx from "clsx";

const UsersList = ({
  isFetched,
  isLastMessagesFetched,
  users,
  onlineMembers,
  theme
}) => {
  let [searchInput, setSearchInput] = React.useState("");
  return (
    <List
      style={{ padding: "8px 24px" }}
      itemLayout="horizontal"
      loading={!isFetched || !isLastMessagesFetched}
      dataSource={Object.values(users).filter(searchUser(searchInput))}
      header={
        <Input
          className={theme === "dark" ? styles.searchDarkMode : ""}
          style={{
            height: "35"
          }}
          onChange={event => setSearchInput(event.target.value)}
          placeholder="Search..."
        />
      }
      renderItem={user => (
        <List.Item className={styles.listItem}>
          <List.Item.Meta
            avatar={
              <Badge
                dot
                status={
                  get(onlineMembers, `${user.username}.status`) === "online"
                    ? "success"
                    : "error"
                }
                style={{ width: "8px", height: "8px" }}
                offset={[-2, 35]}
              >
                <Avatar src={user.avatar} size={45} />
              </Badge>
            }
            title={
              <NavLink
                className={styles.title}
                to={`/chat/${user.id}`}
                activeClassName={styles.selectedUserLink}
                style={{
                  color: theme === "dark" && "rgba(255, 255, 255, 0.65)"
                }}
              >
                {user.firstname} {user.lastname}
              </NavLink>
            }
            description={
              <div
                className={clsx(
                  styles.lastMessageWrapper,
                  theme === "dark" && styles.white
                )}
              >
                {user.lastMessage
                  ? user.lastMessage.subject
                  : "Start chating..."}
              </div>
            }
          />
          <div>
            <Badge count={user.numberOfNewMessages} />
          </div>
        </List.Item>
      )}
    />
  );
};

UsersList.propTypes = {
  isFetched: PropTypes.bool,
  isLastMessagesFetched: PropTypes.bool,
  users: PropTypes.object,
  onlineMembers: PropTypes.object
};

export default UsersList;
