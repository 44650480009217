const initialState = {
  users: {},
  allUsers: [],
  isAllUsersFetched: false,
  isAllUsersFetching: false,
  isFetched: false,
  isFetching: false,
  search: "",
  onlineMembers: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "onStartUsersFetch":
      return { ...state, isFetching: action.isFetching };
    case "onStartAllUsersFetch":
      return { ...state, isAllUsersFetching: true };
    case "setOnlineMembers":
      return {
        ...state,
        onlineMembers: action.members
      };
    case "onOnlineMember":
      return {
        ...state,
        onlineMembers: { ...state.onlineMembers, ...action.member }
      };
    case "resetFetched":
      return {
        ...state,
        isFetched: false
      };
    case "removedMember":
      return {
        ...state,
        onlineMembers: { ...state.onlineMembers, ...action.removedMember }
      };
    case "onUsersFetch":
      return {
        ...state,
        isFetched: true,
        isFetching: false,
        users: action.users
      };
    case "onAllUsersFetch":
      return {
        ...state,
        isAllUsersFetched: true,
        isAllUsersFetching: false,
        allUsers: action.users
      };
    case "resetInitialState":
      return (state = initialState);
    case "onSearchChange":
      return { ...state, search: action.value };
    default:
      return state;
  }
}
