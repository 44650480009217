export const onStartUsersFetch = () => ({
  type: "onStartUsersFetch",
  isFetching: true
});

export const onUsersFetch = users => ({
  type: "onUsersFetch",
  users
});

export const onStartAllUsersFetch = () => ({
  type: "onStartUsersFetch"
});

export const onAllUsersFetch = users => ({
  type: "onAllUsersFetch",
  users
});

export const addMember = member => ({
  type: "onOnlineMember",
  member
});

export const removeMember = removedMember => ({
  type: "removedMember",
  removedMember
});

export const setOnlineMembers = members => ({
  type: "setOnlineMembers",
  members
});

export const onSearchChange = value => ({
  type: "onSearchChange",
  value
});

export const resetFetched = () => ({
  type: "resetFetched"
});
