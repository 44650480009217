import React from "react";
import { Menu, Badge, Avatar, Icon } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import styles from "./styles.module.css";
import PropTypes from "prop-types";
import { userWindow } from "../Profile/enum";

const LoggedUserCoin = ({
  notificationsNumber,
  user,
  history,
  onUserProfileTab,
  theme,
  logout
}) => {
  return (
    <div className={styles.container}>
      <Menu className={styles.connectedMenuNav} mode="inline" theme={theme}>
        <SubMenu
          className={styles.connectedSubMenu}
          title={
            <span className={styles.connectedUserTextContainer}>
              <Badge count={notificationsNumber}>
                <Avatar size={36} src={user.avatar} />
              </Badge>
              <span className={styles.avatarText}>
                {user.firstname} {user.lastname}
              </span>
              <div className={styles.dot}></div>
            </span>
          }
        >
          <Menu.Item
            key="setting:1"
            onClick={() => {
              onUserProfileTab(userWindow.PERSONAL_INFO);
              history.replace("/chat/user/profile");
            }}
          >
            <Icon type="user" />
            Profile
          </Menu.Item>
          <Menu.Item
            key="setting:2"
            onClick={() => {
              onUserProfileTab(userWindow.NORIFICATIONS);
              history.replace("/chat/user/profile");
            }}
          >
            <Icon type="notification" />
            notifications
            <Badge className={styles.badgeNotif} count={notificationsNumber} />
          </Menu.Item>
          <Menu.Item
            key="setting:3"
            onClick={() => {
              onUserProfileTab(userWindow.SETTINGS);
              history.replace("/chat/user/profile");
            }}
          >
            <Icon type="setting" />
            settings
          </Menu.Item>
          <Menu.Item
            key="setting:4"
            onClick={() => {
              logout();
            }}
          >
            <Icon type="logout" />
            Logout
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  );
};

LoggedUserCoin.propTypes = {
  notificationsNumber: PropTypes.number,
  user: PropTypes.object,
  history: PropTypes.object,
  onUserProfileTab: PropTypes.func,
  logout: PropTypes.func
};

export default LoggedUserCoin;
