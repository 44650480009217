const initialState = {
  showEmojiPicker: false,
  reloadApp: false,
  userProfileTab: 1, // For personal info
  theme: "light"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "showEmojiPicker":
      return { ...state, showEmojiPicker: action.value };
    case "resetInitialState":
      return { ...state, reloadApp: true };
    case "setUserProfileTab":
      return { ...state, userProfileTab: action.value };
    case "reloadApp":
      return { ...state, reloadApp: action.value };
    case "setTheme":
      return { ...state, theme: action.value };
    default:
      return state;
  }
}
