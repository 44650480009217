import React from "react";
import { Switch, Route } from "react-router-dom";
import OperationWrapper from "../OperationWrapper";
import get from "lodash/get";
import set from "lodash/set";
import keys from "lodash/keys";
import Pusher from "pusher-js";
import PropTypes from "prop-types";
import { addNewMessageNotification } from "../../redux/messages/thunks";
import { rendMessage, confirmSending } from "../../redux/messages/actions";
import { addNotification } from "../../redux/notifications/actions";
import { updateNotification } from "../../redux/notifications/thunks";
import {
  onSearchChange,
  resetFetched,
  setOnlineMembers,
  addMember,
  removeMember
} from "../../redux/users/actions";

import { notification } from "antd";
import { connect } from "react-redux";

const API_URL = process.env.REACT_APP_API_URL;

Pusher.logToConsole = process.env.PUSHER_DEBUG === "true";

class Chat extends React.Component {
  pusher = new Pusher("f4a1e52b6ee7ec38bd05", {
    cluster: "eu",
    forceTLS: true,
    authEndpoint: `${API_URL}/users/presence`,
    useTLS: true,
    auth: {
      headers: {
        Authorization: "Bearer " + this.props.token
      },
      params: {
        username: this.props.user.username,
        status: "online"
      }
    }
  });

  channel = this.pusher.subscribe("chat");

  componentDidMount() {
    const {
      setOnlineMembers,
      addMember,
      removeMember,
      rendMessage,
      confirmSending,
      addNotification,
      resetFetched,
      addNewMessageNotification,
      user,
      updateNotification
    } = this.props;

    this.channel.bind("message", function(data) {
      if (data.message.from_user === user.username) {
        return confirmSending(data.message);
      }
      addNewMessageNotification(data.message);
      return rendMessage(data.message, user.username);
    });

    this.channel.bind("notification", function(data) {
      if (data.invitationAccepted) {
        notification.success({
          message: "Invitation accepted",
          description: "A new friend is joining the list !"
        });
        resetFetched();
        return updateNotification(data.notificationId);
      }

      if (
        user.pseudo === data.notification.from_user ||
        user.pseudo === data.notification.to_user
      ) {
        addNotification(data.notification);
      }
    });

    const presenceChannel = this.pusher.subscribe("presence-chat");

    presenceChannel.bind("pusher:subscription_succeeded", function(members) {
      setOnlineMembers(members.members);
    });

    presenceChannel.bind("pusher:member_added", function(member) {
      let newMember = set({}, get(member, "id"), get(member, "info"));
      addMember(newMember);
    });

    presenceChannel.bind("pusher:member_removed", function(member) {
      const removedMember = set({}, get(member, "id"), get(member, "info"));
      let normalizedRemovedUser = set({}, keys(removedMember)[0], "");
      removeMember(normalizedRemovedUser);
    });
  }

  componentWillUnmount() {
    this.channel.unbind();
  }

  render() {
    return (
      <Switch>
        <Route component={OperationWrapper} />
      </Switch>
    );
  }
}

Chat.propTypes = {
  token: PropTypes.string,
  rendMessage: PropTypes.func,
  user: PropTypes.object,
  confirmSending: PropTypes.func,
  addNewMessageNotification: PropTypes.func,
  addNotification: PropTypes.func,
  updateNotification: PropTypes.func,
  onSearchChange: PropTypes.func,
  setOnlineMembers: PropTypes.func,
  addMember: PropTypes.func,
  removeMember: PropTypes.func,
  resetFetched: PropTypes.func
};

const mapStateToProps = ({
  auth: { isLoggedIn, user, token },
  appControllers: { reloadApp },
  notifications: { notifications },
  ...props
}) => ({
  ...props,
  notifications,
  notificationsNumber: notifications.length,
  reloadApp,
  user,
  token,
  isLoggedIn
});

const mapDispatchToProps = {
  onSearchChange,
  addNotification,
  addNewMessageNotification,
  rendMessage,
  confirmSending,
  resetFetched,
  updateNotification,
  addMember,
  removeMember,
  setOnlineMembers
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
