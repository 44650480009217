import React from "react";
import styles from "./styles.module.css";
import PropTypes from "prop-types";
import { onSetTheme } from "../../redux/appControllers/actions";
import { confirmInvitation } from "../../redux/notifications/thunks";
import { Avatar, Icon, Card, List, Skeleton, Button, Switch } from "antd";
import WrappedUserProfileForm from "./userForm";
import { connect } from "react-redux";
import clsx from "clsx";

const UserInfo = ({ user }) => {
  return (
    <React.Fragment>
      <div className={styles.avatarTextContainer}>
        {/* TODO */}
        {/* <UserAvatar /> */}
        <Avatar
          size={80}
          icon={
            <Icon style={{ fontSize: "1em", padding: "8px" }} type="user" />
          }
        />
        <div className={styles.userName}>
          <span>
            {user.firstname} {user.lastname}
          </span>
          <div>Toulouse, France</div>
        </div>
      </div>
      <div className={styles.userForm}>
        <WrappedUserProfileForm user={user} />
      </div>
    </React.Fragment>
  );
};

UserInfo.propTypes = {
  user: PropTypes.object
};

const UserInvitations = ({
  list,
  user,
  isNotificationFetching,
  confirmInvitation
}) => {
  return (
    <List
      loading={false}
      itemLayout="horizontal"
      dataSource={list}
      renderItem={item => (
        <List.Item
          actions={
            // TODO cancel a invitation request
            user.id === parseInt(item.user_id)
              ? [
                  <Button disabled key="invitation-cancel">
                    cancel
                  </Button>
                ]
              : [
                  <Button disabled key="invitation-refuse">
                    refuse
                  </Button>,
                  <Button
                    disabled={item.invitationAccepted}
                    key="invitation-confirm"
                    loading={isNotificationFetching}
                    onClick={() => confirmInvitation(item.from_user)}
                  >
                    confirm
                  </Button>
                ]
          }
        >
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              avatar={
                <Avatar
                  size={50}
                  icon={
                    <Icon
                      style={{ fontSize: "1em", padding: "8px" }}
                      type="user"
                    />
                  }
                />
              }
              title={
                item.to_user.charAt(0).toUpperCase() + item.to_user.slice(1)
              }
              description={
                user.id === parseInt(item.user_id)
                  ? "invitation has been sent. Waiting approval by the destination."
                  : item.message
              }
            />
          </Skeleton>
        </List.Item>
      )}
    />
  );
};

UserInvitations.propTypes = {
  list: PropTypes.array,
  user: PropTypes.object,
  isNotificationFetching: PropTypes.bool,
  confirmInvitation: PropTypes.func
};

let Settings = class Settings extends React.Component {
  render() {
    const { theme, onSetTheme } = this.props;
    return (
      <div>
        choose your theme:
        <Switch
          checked={theme === "dark"}
          onChange={value => (value ? onSetTheme("dark") : onSetTheme("light"))}
          checkedChildren="Dark"
          unCheckedChildren="Light"
        />
      </div>
    );
  }
};

Settings.propTypes = {
  theme: PropTypes.string,
  onSetTheme: PropTypes.func
};

const mapStateToPropsSettings = ({ appControllers: { theme } }) => ({
  theme
});

const mapDispatchToPropsSettings = {
  onSetTheme
};

Settings = connect(
  mapStateToPropsSettings,
  mapDispatchToPropsSettings
)(Settings);

const selectedUserWindow = {
  // personal info
  1: (
    <span>
      <Icon type="user" title="User info"></Icon>
      <span className={styles.cardTitleText}>Personal info</span>
    </span>
  ),
  // notifications
  2: (
    <span>
      <Icon type="notification" title="Notifications"></Icon>
      <span className={styles.cardTitleText}>Notifications</span>
    </span>
  ),
  // Settings
  3: (
    <span>
      <Icon type="setting" title="Settings"></Icon>
      <span className={styles.cardTitleText}>Settings</span>
    </span>
  )
};

class Profile extends React.Component {
  tabContents = {
    // personal info
    1: <UserInfo user={this.props.user} />,
    // notifications
    2: (
      <UserInvitations
        list={this.props.notifications}
        user={this.props.user}
        isNotificationFetching={this.props.isNotificationFetching}
        confirmInvitation={this.props.confirmInvitation}
      />
    ),
    3: <Settings />
  };

  render() {
    const { userProfileTab, theme } = this.props;

    return (
      <div
        className={clsx(
          styles.tabs,
          theme === "dark" ? styles.tabDarkMode : styles.tabLightMode
        )}
      >
        <Card title={selectedUserWindow[userProfileTab]} bordered={false}>
          {this.tabContents[userProfileTab]}
        </Card>
      </div>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object,
  notifications: PropTypes.array,
  userProfileTab: PropTypes.number,
  theme: PropTypes.string,
  isNotificationFetching: PropTypes.bool,
  confirmInvitation: PropTypes.func,
  onSetTheme: PropTypes.func
};

const mapStateToProps = ({
  auth: { user },
  appControllers: { userProfileTab, theme },
  notifications: { notifications, isNotificationFetching }
}) => ({
  notifications,
  isNotificationFetching,
  user,
  userProfileTab,
  theme
});

const mapDispatchToProps = {
  confirmInvitation
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
