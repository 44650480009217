import React from "react";
import { Form, Input, Tooltip, Icon, Button } from "antd";
import styles from "./styles.module.css";
import PropTypes from "prop-types";

class UserProfileForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: []
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { user } = this.props;

    const formItemLayout = {
      layout: "vertical"
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <div className={styles.formItemWrapper}>
          <Form.Item className={styles.formItem} label="First name">
            {getFieldDecorator("firstname", {
              rules: [
                {
                  message: "The input is not valid first name!"
                },
                {
                  required: true,
                  message: "Please input your first name!"
                }
              ],
              initialValue: user.firstname
            })(<Input />)}
          </Form.Item>
          <Form.Item className={styles.formItem} label="Last Name">
            {getFieldDecorator("lastname", {
              rules: [
                {
                  message: "The input is not valid last name!"
                },
                {
                  required: true,
                  message: "Please input your last name!"
                }
              ],
              initialValue: user.lastname
            })(<Input />)}
          </Form.Item>
        </div>
        <div className={styles.formItemWrapper}>
          <Form.Item
            className={styles.formItem}
            label={
              <span>
                Pseudo&nbsp;
                <Tooltip title="What do you want others to call you?">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator("nickname", {
              rules: [
                {
                  required: true,
                  message: "Please input your nickname!",
                  whitespace: true
                }
              ],
              initialValue: user.pseudo
            })(<Input />)}
          </Form.Item>
          <Form.Item className={styles.formItem} label="Username">
            {getFieldDecorator("username", {
              rules: [
                {
                  type: "string",
                  message: "The input is not valid user name"
                },
                {
                  required: true,
                  message: "Please input your user name!"
                }
              ],
              initialValue: user.username
            })(<Input disabled />)}
          </Form.Item>
        </div>
        <div className={styles.passwordItems}>
          <Form.Item label="Password" hasFeedback>
            {getFieldDecorator("password", {
              rules: [
                {
                  message: "Please input your password!"
                },
                {
                  validator: this.validateToNextPassword
                }
              ]
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Confirm Password" hasFeedback>
            {getFieldDecorator("confirm", {
              rules: [
                {
                  message: "Please confirm your password!"
                },
                {
                  validator: this.compareToFirstPassword
                }
              ]
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
        </div>

        <Form.Item className={styles.bottomWrapper}>
          <Button type="primary" htmlType="submit">
            Save changes
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

UserProfileForm.propTypes = {
  user: PropTypes.object,
  form: PropTypes.object
};

const WrappedUserProfileForm = Form.create({ name: "userProfile" })(
  UserProfileForm
);

export default WrappedUserProfileForm;
