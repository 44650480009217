import React from "react";
import NavRoute from "./navRoutes";

class App extends React.Component {
  componentDidMount() {
    document.getElementById("app-loader").style.display = "none";
  }

  render() {
    return (
      <React.Fragment>
        <NavRoute />
      </React.Fragment>
    );
  }
}

export default App;
