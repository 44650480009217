export const onAuthen = (user, token) => ({
  type: "onLoginUser",
  user,
  token
});

export const resetInitialState = () => {
  return {
    type: "resetInitialState"
  };
};
