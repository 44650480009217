import { onAuthen, resetInitialState } from "./actions";
import axios from "axios";

export const submitLogin = ({ username, password }) => async dispatch => {
  let result = {
    success: false,
    message: ""
  };

  try {
    const response = await axios.post("/auth", {
      username,
      password
    });

    dispatch(onAuthen(response.data.user, response.data.token));
    result.success = true;
    result.message = "SIGNIN SUCCESSFULY";
    result.type = "success";
  } catch (err) {
    if (!err.response) {
      result.type = "error";
      result.message = "SERVICE NOT AVAILABLE. TRY AGAIN LATER";
    } else if (err.response.status === 401) {
      result.message = "USERNAME/PASSWORD INVALID. TRY AGAIN";
      result.type = "warning";
    } else {
      result.message = "SERVICE NOT AVAILABLE. TRY AGAIN LATER";
      result.type = "error";
    }
  }

  return result;
};

export const logout = () => dispatch => {
  return axios.get("/auth/logout").then(response => {
    if (response.status !== 200) {
      console.log("error on logout but we will reset localstorage");
    }
    console.log("dispatching");
    dispatch(resetInitialState());
  });
};

export const isAuthenticated = () => (dispatch, getState) => {
  const { auth } = getState();

  return auth.isLoggedIn !== null;
};
