import React from "react";
import { Layout } from "antd";
import PropTypes from "prop-types";
import WrappedNormalLoginForm from "../Login";
import { Switch, Route, Redirect } from "react-router-dom";
import Chat from "../Chat";
const { Content } = Layout;

class AppContent extends React.Component {
  render() {
    const { isLoggedIn } = this.props;
    return (
      <Content>
        <Switch>
          <Route
            path="/chat"
            render={({ location }) =>
              isLoggedIn ? (
                <Chat />
              ) : (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: location }
                  }}
                />
              )
            }
          />
          <Route
            render={({ location }) =>
              isLoggedIn ? (
                <Redirect
                  to={{
                    pathname: "/chat",
                    state: { from: location }
                  }}
                />
              ) : (
                <WrappedNormalLoginForm />
              )
            }
          />
        </Switch>
      </Content>
    );
  }
}

AppContent.propTypes = {
  isLoggedIn: PropTypes.bool
};

export default AppContent;
