const initialState = {
  nextUser: null,
  isMessagesFetched: false,
  usersMessages: null,
  file: null,
  isLastMessagesFetched: false,
  isLastMessagesFetching: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "onUserSelect":
      return { ...state, nextUser: action.nextUser, usersMessages: null };
    case "onFetchMessages":
      return {
        ...state,
        usersMessages: action.usersMessages,
        isMessagesFetched: true
      };
    case "rendMessage":
      return {
        ...state,
        usersMessages:
          state.nextUser.username === action.data.message.from_user &&
          action.data.user === action.data.message.to_user
            ? [...state.usersMessages, action.data.message]
            : state.usersMessages
      };
    case "addFile":
      return {
        ...state,
        file: action.file
      };
    case "addMessage":
      return {
        ...state,
        usersMessages: [...state.usersMessages, action.message]
      };
    case "confirmSending":
      return {
        ...state,
        usersMessages: [
          ...state.usersMessages.filter(message => {
            if (message.id === action.message.id) {
              const newMessage = message;
              newMessage.sent = true;
              return newMessage;
            }
            return message;
          })
        ],
        isMessageSent: !state.isMessageSent
      };
    case "setIsLastMessageFetching":
      return {
        ...state,
        isLastMessagesFetching: true
      };
    case "isLastMessageFetched":
      return {
        ...state,
        isLastMessagesFetched: true,
        isLastMessagesFetching: false
      };
    case "resetInitialState":
      return (state = initialState);
    case "setNextUserId":
      return { ...state, nextUser: { id: action.id } };
    default:
      return state;
  }
}
