import React from "react";
import styles from "./styles.module.css";
import { connect } from "react-redux";
import { Icon, Input, Layout } from "antd";
import { fetchUsers } from "../../redux/users/thunks";
import {
  onUserProfileTab,
  onEmojiPicker
} from "../../redux/appControllers/actions";
import {
  fetchMessages,
  resetNewMessageNumber,
  sendMessage,
  addEmoji
} from "../../redux/messages/thunks";

import { Route, Switch } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { logout } from "../../redux/auth/thunks";
import LoggedUserCoin from "./LoggedUserCoin";
import UsersList from "./UsersList";
import Profile from "../Profile";
import LayoutHeader from "./LayoutHeader";
import LayoutMessagesContent from "./LayoutMessagesContent";
import clsx from "clsx";

const { Header, Content, Footer, Sider } = Layout;

const renderField = ({ input, handleSubmit, resetNewMessageNumber, theme }) => {
  console.log(theme === "dark" ? "transparent" : "");
  return (
    <Input
      {...input}
      className={theme === "dark" ? styles.inputMessageDarkMode : ""}
      placeholder="Your message..."
      allowClear
      onPressEnter={handleSubmit}
      onFocus={resetNewMessageNumber}
    />
  );
};

class ChatterWrapper extends React.Component {
  componentDidMount() {
    if (this.props.isFetched) {
      let userId = this.props.match.params.id;
      this.props.fetchMessages(userId);
    }
  }

  componentDidUpdate() {
    if (this.props.isFetched) {
      let userId = this.props.match.params.id;
      this.props.fetchMessages(userId);
    }
  }

  render() {
    const {
      nextUser,
      onlineMembers,
      messages,
      user,
      theme,
      resetNewMessageNumber,
      handleSubmit,
      showEmojiPicker,
      onEmojiPicker,
      addEmoji
    } = this.props;

    return (
      <Layout className={theme === "dark" ? styles.layoutDarkMode : ""}>
        <Header
          className={clsx(
            styles.chatterHeader,
            theme === "dark" && styles.headerDarkMode
          )}
        >
          <LayoutHeader
            nextUser={nextUser}
            onlineMembers={onlineMembers}
            messages={messages}
          />
        </Header>

        <Content className={styles.content}>
          <LayoutMessagesContent
            onEmojiPicker={onEmojiPicker}
            showEmojiPicker={showEmojiPicker}
            addEmoji={addEmoji}
            messages={messages}
            nextUser={nextUser}
            user={user}
            theme={theme}
          />
        </Content>

        <Footer
          className={styles.footer}
          style={{ background: theme === "dark" ? "#001529" : "#fff" }}
        >
          <form onSubmit={handleSubmit} className={styles.myInput}>
            <Icon
              type="smile"
              className={styles.inputIcon}
              onClick={() => onEmojiPicker(true)}
            />
            <Icon type="paper-clip" className={styles.inputIcon} />
            <Field
              name="message"
              handleSubmit={handleSubmit}
              component={renderField}
              theme={theme}
              resetNewMessageNumber={resetNewMessageNumber}
            />
          </form>
        </Footer>
      </Layout>
    );
  }
}

let Chatter = reduxForm({ form: "message" })(ChatterWrapper);

class OperationWrapper extends React.Component {
  componentDidMount() {
    this.props.fetchUsers();
  }
  render() {
    const {
      users,
      isFetched,
      isLastMessagesFetched,
      nextUser,
      fetchMessages,
      onlineMembers,
      usersMessages,
      resetNewMessageNumber,
      sendMessage,
      user,
      theme,
      onUserProfileTab,
      notificationsNumber,
      showEmojiPicker,
      onEmojiPicker,
      addEmoji,
      history,
      logout
    } = this.props;

    return (
      <Layout
        className={clsx(
          styles.layout,
          theme === "dark" && styles.layoutDarkMode
        )}
      >
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={broken => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          width={350}
          className={styles.sider}
          theme={theme}
        >
          <LoggedUserCoin
            theme={theme}
            history={history}
            logout={logout}
            notificationsNumber={notificationsNumber}
            onUserProfileTab={onUserProfileTab}
            user={user}
          />
          <UsersList
            isFetched={isFetched}
            isLastMessagesFetched={isLastMessagesFetched}
            users={users}
            theme={theme}
            onlineMembers={onlineMembers}
          />
        </Sider>
        <Switch>
          <Route path="/chat/user/profile" component={Profile}></Route>

          <Route
            path="/chat/:id"
            render={props => (
              <Chatter
                {...props}
                isLastMessagesFetched={isLastMessagesFetched}
                onlineMembers={onlineMembers}
                isFetched={isFetched}
                fetchMessages={fetchMessages}
                resetNewMessageNumber={resetNewMessageNumber}
                messages={usersMessages}
                nextUser={nextUser}
                onSubmit={sendMessage}
                user={user}
                onEmojiPicker={onEmojiPicker}
                showEmojiPicker={showEmojiPicker}
                theme={theme}
                addEmoji={addEmoji}
              />
            )}
          />
        </Switch>
      </Layout>
    );
  }
}

const mapStateToProps = ({
  auth: { user },
  users: { users, isFetching, isFetched, onlineMembers },
  messages: { isLastMessagesFetched, nextUser, usersMessages },
  appControllers: { showEmojiPicker, theme },
  notifications: { notifications }
}) => ({
  theme,
  users,
  user,
  nextUser,
  onlineMembers,
  usersMessages,
  isFetched,
  isFetching,
  showEmojiPicker,
  isLastMessagesFetched,
  notificationsNumber: notifications.length
});

const mapDispatchToProps = {
  fetchUsers,
  fetchMessages,
  resetNewMessageNumber,
  sendMessage,
  onUserProfileTab,
  onEmojiPicker,
  addEmoji,
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(OperationWrapper);
