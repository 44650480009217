import React from "react";
import styles from "./styles.module.css";
import moment from "moment";
import PropTypes from "prop-types";
import { Icon, Avatar } from "antd";

const Message = ({ message, messageType, messageSide, userAvatar }) => (
  <div className={messageType}>
    {message.show_avatar ? (
      <Avatar size="large" className={styles.avatar} src={userAvatar} />
    ) : (
      <div className={styles.noAvatar} />
    )}
    <div className={styles.messageContainer}>
      <div className={messageSide}>{message.subject}</div>
      {message.sent && (
        <div className={styles.time}>
          {moment(message.date).format("HH:mm")}
          <Icon className={styles.done} type="check" />
        </div>
      )}
    </div>
  </div>
);

Message.propTypes = {
  message: PropTypes.object,
  messageType: PropTypes.string,
  messageSide: PropTypes.string,
  userAvatar: PropTypes.string
};

export default Message;
