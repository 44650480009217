import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import reduxStore from "./redux/reducers";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import { configureErrorHandler } from "./axios-intercepter.js";
import * as serviceWorker from "./serviceWorker";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

configureErrorHandler();

ReactDOM.render(
  <Provider store={reduxStore.store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={reduxStore.persistor}>
        <Route component={App}></Route>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
