export const setNotifications = value => ({
  type: "setNotifications",
  value
});

export const addNotification = value => ({
  type: "addNotification",
  value
});

export const setInvitationsNumber = value => ({
  type: "setInvitationsNumber",
  value
});

export const setInvitationAccepted = () => ({
  type: "setInvitationAccepted"
});

export const setSignInMessage = value => ({
  type: "setSignInMessage",
  value
});

export const setIsNotificationFetching = value => ({
  type: "setIsFetching",
  value
});
