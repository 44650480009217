import React from "react";
import { Route, Switch } from "react-router-dom";
import ChatApp from "./components/ChatApp";
import { connect } from "react-redux";
import GenericNotFound from "./components/GenericNotFound";
import axios from "axios";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import Loader from "./components/AppLoader";

class NavRoute extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    if (this.props.token) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.token;
    }
    this.setState({ loading: false });
  }

  componentDidUpdate() {
    let tokenParams = axios.defaults.headers.common["Authorization"];
    let token = tokenParams && tokenParams.split(" ")[1];

    if (this.props.token && this.props.token !== token) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.token;
    }
  }

  render() {
    const { reloadApp } = this.props;

    if (reloadApp) {
      sessionStorage.removeItem("persist:root");
      localStorage.removeItem("persist:root");
      window.location.replace("/");
      return null;
    }

    if (this.state.loading) {
      return (
        <div className={styles.appLoaderWrapper}>
          <Loader />
        </div>
      );
    }

    return (
      <Switch>
        <Route path="/chat" component={ChatApp}></Route>
        <Route path="/" exact component={ChatApp}></Route>
        <Route component={GenericNotFound}></Route>
      </Switch>
    );
  }
}

NavRoute.propTypes = {
  token: PropTypes.string,
  reloadApp: PropTypes.bool
};

const mapStateToProps = ({
  auth: { token },
  appControllers: { reloadApp },
  ...props
}) => ({
  ...props,
  token,
  reloadApp
});

export default connect(mapStateToProps, null)(NavRoute);
