import React, { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import PropTypes from "prop-types";
import Message from "../Message";
import messageStyle from "../Message/styles.module.css";
import EmojiHolder from "../EmojiHolder";

const LayoutMessagesContent = ({
  messages,
  nextUser,
  user,
  theme,
  showEmojiPicker,
  onEmojiPicker,
  addEmoji
}) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  return (
    <div className={styles.messagesContainer}>
      {messages &&
        nextUser &&
        messages.map((message, index) =>
          message.from_user !== user.username ? (
            <Message
              message={message}
              messageType={messageStyle.containerLeft}
              messageSide={messageStyle.messageLeft}
              key={index}
              userAvatar={nextUser.avatar}
            />
          ) : (
            <Message
              message={message}
              messageType={messageStyle.containerRight}
              messageSide={messageStyle.messageRight}
              key={index}
              userAvatar={user.avatar}
            />
          )
        )}
      <div ref={messagesEndRef} />

      <EmojiHolder
        theme={theme}
        showEmojiPicker={showEmojiPicker}
        addEmoji={addEmoji}
        onEmojiPicker={onEmojiPicker}
      />
    </div>
  );
};

LayoutMessagesContent.propTypes = {
  theme: PropTypes.string,
  messages: PropTypes.array,
  nextUser: PropTypes.object,
  onEmojiPicker: PropTypes.func,
  addEmoji: PropTypes.func,
  user: PropTypes.object,
  showEmojiPicker: PropTypes.bool
};

export default LayoutMessagesContent;
