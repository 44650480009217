import * as actions from "./actions";
import axios from "axios";

export const sendNotification = pseudoFriend => async () => {
  try {
    let result = await axios.post("/users/notifications/send", {
      toUser: pseudoFriend
    });

    if (result.status !== 200) {
      const message = result.message
        ? result.message
        : "Error occured while processing your request";
      return {
        success: false,
        message: message
      };
    }
    return result.data;
  } catch (error) {
    return {
      success: false,
      message: "Error occured while processing your request"
    };
  }
};

export const confirmInvitation = pseudoFriend => dispatch => {
  dispatch(actions.setIsNotificationFetching(true));
  axios.put("/users/notifications/invite", { pseudoFriend });
};

export const updateNotification = notificationId => (dispatch, getState) => {
  const { notifications } = getState();

  let notifs = notifications.notifications.map(notif => {
    if (notif.id === notificationId) {
      notif.invitationAccepted = true;
    }
    return notif;
  });

  dispatch(actions.setNotifications(notifs));
};
