import axios from "axios";
import { handleErrorMessage } from "./redux/common";

export const configureErrorHandler = () => {
  // Add a response interceptor
  let alreadyGotAnError = false;
  axios.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (!alreadyGotAnError) {
        alreadyGotAnError = true;
        handleErrorMessage(error);
        return Promise.reject(error);
      }
    }
  );
};
