import React from "react";
import styles from "./styles.module.css";
import { Layout, Row, Col, Avatar, Menu } from "antd";
import PropTypes from "prop-types";

const { Header } = Layout;

class AppHeader extends React.Component {
  state = {
    current: "signIn"
  };

  handleClick = e => {
    this.setState({ current: e.key });
    this.props.history.push(e.item.props.link);
  };

  render() {
    const { isLoggedIn, reloadApp } = this.props;
    const { current } = this.state;

    if (!reloadApp && isLoggedIn) {
      return null;
    }

    return (
      <Header className={styles.header}>
        <Row>
          <Col
            className={styles.logoWrapper}
            span={8}
            xs={24}
            sm={24}
            md={5}
            lg={5}
            xl={5}
            xxl={4}
          >
            <a className={styles.logo} href="/">
              <Col className={styles.logoChanty} xs={0} sm={0} md={3}>
                <Avatar src="/chat-icon.png" size={64} />
              </Col>
              <div className={styles.chanty}>CHANTY</div>
            </a>
          </Col>
          <Col xs={0} sm={0} md={19} lg={19} xl={19} xxl={20}>
            <Menu
              className={styles.navMenu}
              onClick={this.handleClick}
              selectedKeys={[current]}
              mode="horizontal"
            >
              <Menu.Item className={styles.menuItem} key="signIn" link="/">
                My Portal
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
      </Header>
    );
  }
}

AppHeader.propTypes = {
  reloadApp: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  history: PropTypes.object
};

export default AppHeader;
