import React from "react";
import { Layout } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { onUserProfileTab } from "../../redux/appControllers/actions";
import AppHeader from "./AppHeader";
import AppContent from "./AppContent";

class ChatApp extends React.Component {
  render() {
    const { isLoggedIn, history, reloadApp, user } = this.props;

    return (
      <Layout style={{ backgroundColor: "#ffffff" }}>
        <AppHeader
          isLoggedIn={isLoggedIn}
          reloadApp={reloadApp}
          history={history}
        />
        <AppContent
          onUserProfileTab={onUserProfileTab}
          isLoggedIn={isLoggedIn}
          user={user}
        />
      </Layout>
    );
  }
}

ChatApp.propTypes = {
  history: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  reloadApp: PropTypes.bool,
  user: PropTypes.object,
  notificationsNumber: PropTypes.number,
  notifications: PropTypes.array
};

const mapStateToProps = ({
  auth: { isLoggedIn, user },
  appControllers: { reloadApp },
  notifications: { notifications },
  ...props
}) => ({
  ...props,
  notifications,
  reloadApp,
  user,
  isLoggedIn
});

export default connect(mapStateToProps)(ChatApp);
