import { message } from "antd";
import localStorage from "redux-persist/es/storage";

export const handleErrorMessage = error => {
  if (
    error.response &&
    (error.response.status === 401 || error.response.status === 400)
  ) {
    return message.loading("Session expired. Redirecting...", 3).then(() => {
      localStorage.removeItem("persist:root");
      window.location.replace("/");
    });
  }

  if (error.response && error.response.status === 403) {
    return message.warning(
      "You are not authorized to perform this action.",
      10
    );
  }

  if (error.response && error.response.status === 503) {
    return message.warning("Internal server error.", 10);
  }

  return message.error(
    "Unknown error. Try signout and then signin to solve the issue.",
    10
  );
};
